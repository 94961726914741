// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_WX3kEixCH",
    "aws_user_pools_web_client_id": "6lr7u6gtfjrietjaee7205tu7k",
    "oauth": {},
    "aws_content_delivery_bucket": "react-amplify-20191127160520-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://react-amplify-20191127160520-hostingbucket-dev.s3-website-eu-west-1.amazonaws.com"
};


export default awsmobile;
